<template lang="pug">
.container.rounded-bottom
  .row
    .col
      radar-chart.my-5(
        v-if="!isLoading"
        ref="chart"
        :chart-data="analysis"
        :options="options"
      )
  .wuwow-card
    .wuwow-card-head.text-center
      h4.wuwow-card-title {{ material }}
    .wuwow-card-body
      .row.border-dark.py-4
        .col-md-3.d-flex.justify-content-center
          font-awesome-icon(icon="calendar-minus" size="lg").mr-2
          | {{ createdAt }}
        .col-md-3.d-flex.justify-content-center
          font-awesome-icon(icon="user" size="lg").mr-2
          | {{ name.consultant }}
        .col-md-3.d-flex.justify-content-center
          font-awesome-icon(icon="users" size="lg").mr-2
          | {{ name.vip }}
        .col-md-3.d-flex.justify-content-center
          font-awesome-icon(icon="chart-line" size="lg").mr-2
          | Lv. {{ averageLevel }}
      .row
        .col(v-if="analysis")
          b-alert(show v-for="(item, index) in analysis.labels")
            span.mr-2 {{item}}
            span level {{level[item.toLowerCase()]}}
      .row
        .col
          b-form-textarea(
            v-model="comment.suggestionOfConsultant"
            :rows="6"
            readonly
          ).mt-1.mb-3
      .row
        .col.d-flex.justify-content-center.align-items-center
          b-button.mr-3(@click="goBack()" variant="secondary") Back
</template>

<script>
import RadarChart from '@/components/memberClassRecords/viewAnalysis/radarChart/index.vue';

import api, { Platform } from '@lioshutan/api-package';

export default {
  components: {
    Comment,
    RadarChart,
  },
  data: () => ({
    isLoading: false,
    token: null,
    averageLevel: 0,
    createdAt: null,
    material: '',
    name: {
      vip: '',
      consultant: '',
    },
    comment: {
      suggestionOfConsultant: '',
    },
    level: {
      vocabulary: 0,
      listening: 0,
      grammar: 0,
      speaking: 0,
      reading: 0,
      pronunciation: 0,
    },
    analysis: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
      scale: {
        ticks: {
          min: 0,
          max: 6,
        },
        pointLabels: {
          fontSize: 16,
          fontFamily: 'Noto Sans TC,sans-serif',
        },
      },
      legend: {
        labels: {
          fontSize: 16,
          fontFamily: 'Noto Sans TC,sans-serif',
        },
      },
    },
  }),
  async created() {
    const loading = this.$loading.show();
    this.isLoading = true;
    this.token = this.$store.state.auth.token;
    const {
      data,
    } = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .analysis(this.token)
      .consultantGetStudentAnalysis({
        platform: Platform.WUWOW_JUNIOR,
        vipId: this.$route.query.vipId,
        classApplicationId: this.$route.query.caId,
      });

    this.analysis = {
      labels: [
        'Vocabulary',
        'Grammar',
        'Listening',
        'Speaking',
        'Reading',
        'Pronunciation',
      ],
      datasets: [
        {
          data: [
            data.vocabularyLevel,
            data.grammarLevel,
            data.listeningLevel,
            data.speakingLevel,
            data.readingLevel,
            data.pronunciationLevel,
          ],
          label: 'level',
          backgroundColor: '#4085c0',
        },
      ],
    };

    this.material = data.material.title;

    this.name.vip = data.vip.englishName;
    this.name.consultant = data.consultant.englishName;
    this.comment.suggestionOfConsultant = data.suggestionOfConsultant;
    // JR老師不用填這些評語 只要總結就好
    // this.comment.vocabulary = data.suggestionOfVocabulary;
    // this.comment.listening = data.suggestionOfListening;
    // this.comment.grammar = data.suggestionOfGrammar;
    // this.comment.speaking = data.suggestionOfSpeaking;
    // this.comment.reading = data.suggestionOfReading;
    // this.comment.pronunciation = data.suggestionOfPronunciation;

    this.level.vocabulary = data.vocabularyLevel;
    this.level.grammar = data.grammarLevel;
    this.level.listening = data.listeningLevel;
    this.level.speaking = data.speakingLevel;
    this.level.reading = data.readingLevel;
    this.level.pronunciation = data.pronunciationLevel;

    this.averageLevel = data.averageLevel;
    this.createdAt = data.createdAt;
    loading.hide();
    this.isLoading = false;
  },

  beforeDestroy(){
    this.isLoading = false;
  },

  methods: {
    goBack(){
      this.$router.push({ path: '/console/consultant/classRecords' });
    },
  },
};
</script>
