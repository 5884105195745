var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container rounded-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [!_vm.isLoading ? _c('radar-chart', {
    ref: "chart",
    staticClass: "my-5",
    attrs: {
      "chart-data": _vm.analysis,
      "options": _vm.options
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head text-center"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.material))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('div', {
    staticClass: "row border-dark py-4"
  }, [_c('div', {
    staticClass: "col-md-3 d-flex justify-content-center"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "calendar-minus",
      "size": "lg"
    }
  }), _vm._v(_vm._s(_vm.createdAt))], 1), _c('div', {
    staticClass: "col-md-3 d-flex justify-content-center"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "user",
      "size": "lg"
    }
  }), _vm._v(_vm._s(_vm.name.consultant))], 1), _c('div', {
    staticClass: "col-md-3 d-flex justify-content-center"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "users",
      "size": "lg"
    }
  }), _vm._v(_vm._s(_vm.name.vip))], 1), _c('div', {
    staticClass: "col-md-3 d-flex justify-content-center"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "chart-line",
      "size": "lg"
    }
  }), _vm._v("Lv. " + _vm._s(_vm.averageLevel))], 1)]), _c('div', {
    staticClass: "row"
  }, [_vm.analysis ? _c('div', {
    staticClass: "col"
  }, _vm._l(_vm.analysis.labels, function (item, index) {
    return _c('b-alert', {
      attrs: {
        "show": ""
      }
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(item))]), _c('span', [_vm._v("level " + _vm._s(_vm.level[item.toLowerCase()]))])]);
  }), 1) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('b-form-textarea', {
    staticClass: "mt-1 mb-3",
    attrs: {
      "rows": 6,
      "readonly": ""
    },
    model: {
      value: _vm.comment.suggestionOfConsultant,
      callback: function ($$v) {
        _vm.$set(_vm.comment, "suggestionOfConsultant", $$v);
      },
      expression: "comment.suggestionOfConsultant"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col d-flex justify-content-center align-items-center"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Back")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }